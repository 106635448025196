jQuery(function() {
    console.log( "ready!" );


	// Colorbox 

    jQuery(".media-item-image").colorbox({rel:'carousel-image', transition:"fade", maxWidth:'95%', maxHeight:'95%'});
    jQuery(".media-item-video").colorbox({rel:'carousel-image', iframe:true, innerWidth:'80%', innerHeight:'80%', maxWidth:'95%', maxHeight:'95%'}); //, innerWidth:640, innerHeight:390
    jQuery(".media-item-embed").colorbox({rel:'carousel-image', inline:true, maxWidth:'95%', maxHeight:'95%'});
    
    /* Colorbox resize function */
	var resizeTimer;
	function resizeColorBox() {
	    if (resizeTimer) {
	    	clearTimeout(resizeTimer); 
	    }
	    resizeTimer = setTimeout(function() {
	            if (jQuery('#cboxOverlay').is(':visible')) {
	            	jQuery.colorbox.resize({width:'90%', height:'90%'});
	            }
	    }, 300);
	}

	// Resize Colorbox when resizing window or changing mobile device orientation
	jQuery(window).resize(resizeColorBox);
	window.addEventListener("orientationchange", resizeColorBox, false);
		
});